
import { computed, defineComponent, onMounted, reactive } from 'vue'
import { useRoute, useRouter } from 'vue-router'

export default defineComponent({
  setup() {
    const route = useRoute();
    const path = computed(() =>fullLink.value + route.fullPath)
    let fullLink = reactive({
      value: 'https://seguro.actionaid.org.br/aguaevida'
    })
    return {fullLink,path}
  },
})
